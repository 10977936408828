import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import './locationCards.css';

const LocationCards = () => {
  const locations = [
    { title: 'ABUJA OFFICE', address: 'TRAMAS Bus terminal, 2nd Ave junction. Festac Town Lagos State.', phone: '07053880045, 07037103488,  08033446066' },
    { title: 'LAGOS OFFICE', address: 'TRAMAS Bus terminal, 2nd Ave junction. Festac Town Lagos State.', phone: '07053880045,08039506440,  08033963423' },
    { title: 'AWKA OFFICE', address: 'Shop 3A, Sanco park, plot 3, Enugu/Onitsha Exp. Way Unizik Junction', phone: '07053880045, 08037148496, 08034914953' },
  ];

  return (
    <div className='location' id='terminal'>
       <div className='terminal'>Our Terminals</div>
    <div className="location-cards">
      {locations.map((location, index) => (
        <div key={index} className="card">
          <h3 className='address'>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
            {location.title}
          </h3>
          <p>{location.address}</p>
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} className="phone-icon" />
            {location.phone}
          </p>
        </div>
      ))}
    </div>
    </div>
  );
};

export default LocationCards;
