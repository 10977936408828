import React, { useState } from 'react';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faYoutube, faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Login from '../login/login';
import Signup from '../login/signup';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLoginClick = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  const handleSignupClick = () => {
    setShowSignup(true);
    setShowLogin(false);
  };

  const closePopup = () => {
    setShowLogin(false);
    setShowSignup(false);
  };

  const toggleServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="contact-info">
            <span>📞 +234 7053 880 045</span>
            <span>🕒 Monday to Sunday: 9:00am – 6:00pm</span>
          </div>
          <div className="additional-info">
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPinterestP} />
              </a>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="logo">
            <img src={require('../../images/logo.png')} alt="Transpi" />
          </div>
          <nav className={`nav ${isOpen ? 'open' : ''}`}>
            <ul>
              <li>
                <a href="/">HOME</a> 
              </li>
              <li>
                <a href="/About">ABOUT</a> 
              </li>
              <li className="services-dropdown" onMouseEnter={toggleServicesDropdown} onMouseLeave={toggleServicesDropdown}>
                <a href="#service">SERVICES <FontAwesomeIcon icon={faChevronDown} /></a>
                {showServicesDropdown && (
                  <ul className="dropdown">
                    <li><a href="/">TRANSPORT SERVICES</a></li>
                    <li><a href="/logistics">COURIER AND LOGISTICS</a></li>
                    <li><a href="/storage">MOVING AND STORAGE</a></li>
                  </ul>
                )}
              </li>
              <li>
                <a href="#contact">CONTACT</a>
              </li>
              <button className="loginn" onClick={handleLoginClick}>Login</button>
              <button className="signuup" onClick={handleSignupClick}>Sign Up</button>
            </ul>
            {isOpen && (
              <div className="nav-actions">
                <button className="login" onClick={handleLoginClick}>Login</button>
                <button className="signup" onClick={handleSignupClick}>Sign Up</button>
              </div>
            )}
          </nav>

          <button className="hamburger" onClick={toggleMenu}>
            {isOpen ? <FontAwesomeIcon icon={faTimes} /> : '☰'}
          </button>
        </div>
      </header>
      {showLogin && (
        <div className="show-popup">
          <Login onClose={closePopup} />
          <div onClick={closePopup}></div>
        </div>
      )}

      {showSignup && (
        <div className="show-popup">
          <Signup onClose={closePopup} />
          <div onClick={closePopup}></div>
        </div>
      )}
    </>
  );
};

export default Header;
