import React, { useRef, useState } from "react";
import './bookingcomp.css';
import { MdDateRange } from "react-icons/md";
import { FaRightLeft } from "react-icons/fa6";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Bookingcomp = () => {
  const ref_depart = useRef();
  const ref_arrive = useRef();
  const [tab, tabChanged] = useState("seat");
  const navigate = useNavigate(); 

  const handleProceedClick = () => {
    navigate("/additionalinfo");
  };

  return (
    <div className="header-container">
      {/* Header */}
      <div className="header-main">
        <div className="header-flex">
          <div
            className={
              tab === "seat"
                ? "header-tab header-tab-active"
                : "header-tab header-tab-inactive"
            }
            onClick={() => tabChanged("seat")}
          >
            <span className="tab-text mobile-inactive">Book a seat</span>
          </div>
          <div
            className={
              tab === "bus"
                ? "header-tab header-tab-active"
                : "header-tab header-tab-inactive"
            }
            onClick={() => tabChanged("bus")}
          >
            <span className="tab-text">Hire a bus</span>
          </div>
          <div
            className={
              tab === "status"
                ? "header-tab header-tab-active"
                : "header-tab header-tab-inactive"
            }
            onClick={() => tabChanged("status")}
          >
            <span className="tab-text">Booking status</span>
          </div>
        </div>
      </div>

      <div className="header-container-main">
        {tab !== "status" && (
          <>
            <h3 className="header-title">
              {tab === "bus" ? "Let us manage your next hire" : "Let us manage your next travel"}
            </h3>
            <p className="header-subtitle">Arriving safely is our goal</p>
            <div className="trip-type">
              <input id="one" type="radio" name="trip" />
              <label htmlFor="one">One-way</label>
              <input id="two" type="radio" name="trip" style={{ marginLeft: '10px' }} />
              <label htmlFor="two">Round Trip</label>
            </div>
          </>
        )}

        {tab === "seat" || tab === "bus" ? (
          <div className="header-container-main-2" style={{ width: '90%' }}>
            <div className="destination-date">
              <p className="trip-detail-header">
                {tab === "bus" ? "Hire From" : "Destination"}
              </p>
              <div className="simple-flex">
                <select placeholder={tab === "bus" ? "Hire From" : "From"}>
                  <option>Aba</option>
                  <option>Abakaliki</option>
                  <option>Abuja</option>
                  <option>Awka</option>
                  <option>Ekwulobia</option>
                  <option>Lagos</option>
                  <option>Onitsha</option>
                  <option>Owerri</option>
                  <option>PH</option>
                </select>
                <div className="iconCircle">
                  <FaRightLeft />
                </div>
                <select placeholder={tab === "bus" ? "Hire To" : "To"}>
                  <option>Aba</option>
                  <option>Abakaliki</option>
                  <option>Abuja</option>
                  <option>Awka</option>
                  <option>Ekwulobia</option>
                  <option>Lagos</option>
                  <option>Onitsha</option>
                  <option>Owerri</option>
                  <option>PH</option>
                </select>
              </div>
              <p className="trip-detail-header">Date(s)</p>
              <div className="simple-flex">
                <input
                  placeholder="Departure Date"
                  ref={ref_depart}
                  onChange={(e) => console.log(e.target.value)}
                  onFocus={() => (ref_depart.current.type = "date")}
                  onBlur={() => (ref_depart.current.type = "text")}
                />
                <div className="iconCircle">
                  <MdDateRange />
                </div>
                <input
                  placeholder="Return Date"
                  ref={ref_arrive}
                  onChange={(e) => console.log(e.target.value)}
                  onFocus={() => (ref_arrive.current.type = "date")}
                  onBlur={() => (ref_arrive.current.type = "text")}
                />
              </div>
            </div>
            {tab === "seat" && (
              <div>
                <p className="trip-detail-header">Other Info</p>
                <div className="simple-flex">
                  <input placeholder="Travellers" list="traveller" />
                  <datalist id="traveller">
                    <option>1 Adult</option>
                    <option>2 Adults</option>
                    <option>3 Adults</option>
                    <option>4 Adults</option>
                    <option>5+ Adults</option>
                  </datalist>
                  <div className="iconCircle">
                    <BsFillPersonVcardFill />
                  </div>
                  <select>
                    <option>--Select Vehicle--</option>
                    <option>AC Bus</option>
                    <option>Non AC Bus</option>
                    <option>Sienna</option>
                  </select>
                </div>
              </div>
            )}
            <button className="scheduleButton" onClick={handleProceedClick}>
              Proceed
            </button>
          </div>
        ) : (
          <div className="header-container-main-2 " style={{ width: '90%' }}>
            <p className="trip-detail-header">Enter your reference code</p>
            <input placeholder="Reference Code" />
            <button className="scheduleButton">Check Status</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookingcomp;
