import React from 'react';
import './herosection.css';
import Bookingcomp from '../booking/bookingcomp';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
      navigate('/selection');
    };
  
    return (
        <div className="hero-container">
            <div className="hero-left">
                <div className="hero-content">
                <h4>TRAMAS</h4>
                    <h1>Fast, Secure, and Comfortable Transportation, Courier and Logistics Services.</h1>
                    <p>We provide top-notch road transportation services, ensuring your journey is safe, comfortable, and on time. Whether you're traveling for business or leisure, we've got you covered with our reliable services.</p>
                    <div className="hero-buttons">
                        <button className="services-btn" onClick={handleRedirect}>All Services</button>
                        <button className="call-btn">
                            <i className="fas fa-phone-alt"></i> Call Us Now <span>+237 7053 880 045</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="hero-right">
               <Bookingcomp/>
            </div>
        </div>
    );
}

export default HeroSection;
