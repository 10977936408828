import React, { useState } from "react";
import "./logistics.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";

const Logistics = () => {
  const [logistics, logisticsChanged] = useState([
    { route: "Lagos (Ikeja) ==> Abuja (Garki)", status: "Delivered (On Time)", cost: "15,000 NGN", requestDate: "August 12th, 2024", deliveryDate: "August 14th, 2024", itemType: "Documents" },
    { route: "Kano (Bompai) ==> Kaduna (Barnawa)", status: "Delivered (Delayed)", cost: "22,000 NGN", requestDate: "August 5th, 2024", deliveryDate: "August 7th, 2024", itemType: "Furniture" },
    { route: "Enugu (New Haven) ==> Anambra (Onitsha)", status: "In Transit", cost: "10,500 NGN", requestDate: "August 20th, 2024", deliveryDate: "Expected August 23rd, 2024", itemType: "Clothing" },
    { route: "Port Harcourt (Mile 1) ==> Calabar (Marian)", status: "Delivered (On Time)", cost: "8,000 NGN", requestDate: "July 30th, 2024", deliveryDate: "August 1st, 2024", itemType: "Groceries" }
  ]);
  
  const [openLogistics, openLogisticsChanged] = useState(false);

  return (
    <div className="logistics-container">
      <div className="order-table">
        <div className="order-header">
          <h3>Logistics History</h3>
          <button className="add-order">+ Add New Logistic</button>
        </div>
        <center>
          {
            openLogistics ? (
              <div className="delivered-items delivered-items-grid">
                <BsArrowLeftCircleFill style={{fontSize:'30px'}} onClick={() => openLogisticsChanged(false)} />
                <p></p>
                <p>Route</p>
                <p style={{fontWeight:'normal'}}>Lagos (Ikeja) == Abuja (Garki)</p>
                <p>Status</p>
                <p style={{fontWeight:'normal'}}>Delivered (On Time)</p>
                <p>Contact Name</p>
                <p style={{fontWeight:'normal'}}>John Doe</p>
                <p>Contact Info</p>
                <p style={{fontWeight:'normal'}}>08012345678 (john.doe@example.com)</p>
                <p>Total Cost</p>
                <p style={{fontWeight:'normal'}}>15,000 NGN</p>
                <p>Request Date</p>
                <p style={{fontWeight:'normal'}}>August 12th, 2024</p>
                <p>Delivery Date</p>
                <p style={{fontWeight:'normal'}}>August 14th, 2024</p>
                <p>Item Type</p>
                <p style={{fontWeight:'normal'}}>Documents</p>
              </div>
            ) : (
              <>
                {logistics.map(item => (
                  <div className="delivered-items" onClick={() => openLogisticsChanged(true)}>
                    <div className="delivered-items-spacebetween">
                      <p>{item.route}</p>
                      <p><b>{item.status}</b></p>
                    </div>
                    <div className="delivered-items-spacebetween">
                      <p>{item.requestDate}</p>
                      <p><b>{item.cost}</b></p>
                    </div>
                  </div>
                ))}
              </>
            )
          }
        </center>
      </div>
    </div>
  );
};

export default Logistics;
