import React, { useState } from "react";
import './addinfo.css';

const AdditionalInfo = () => {
  const [pickupOption, setPickupOption] = useState(null);

  const handlePickupChange = (e) => {
    setPickupOption(e.target.value);
  };

  return (
    <div className="add-body">
      <section className="add-container">
        <header>Personal Information</header>
        <form action="#" className="add-form">
          <div className="column">
            <div className="input-boox">
              <label>Full Name</label>
              <input type="name" placeholder="Enter Your Full Name" required />
            </div>
            <div className="input-boox">
              <label>Phone Number</label>
              <input type="number" placeholder="Enter your phone number" required />
            </div>
          </div>
          <div className="column">
            <div className="input-boox">
              <label>Name of Next of Kin</label>
              <input type="name" placeholder="Enter Next of Kin Full Name" required />
            </div>
            <div className="input-boox">
              <label>Next of Kin Phone Number</label>
              <input type="number" placeholder="Enter phone number of Next of Kin" />
            </div>
          </div>
          <div className="pickup-boox">
            <h3>Pickup options: <i>Kindly note that pickup attracts additional charges</i></h3>
            <div className="pickup-option">
              <div className="pickup">
                <input
                  type="radio"
                  id="check-home"
                  name="pickup"
                  value="home"
                  checked={pickupOption === "home"}
                  onChange={handlePickupChange}
                />
                <label htmlFor="check-home">Yes, Pickup from home</label>
              </div>
              <div className="pickup">
                <input
                  type="radio"
                  id="check-other-means"
                  name="pickup"
                  value="other"
                  checked={pickupOption === "other"}
                  onChange={handlePickupChange}
                />
                <label htmlFor="check-other-means">No, other means</label>
              </div>
            </div>
          </div>
          
          {pickupOption === "home" && (
            <div className="input-boox addresss">
              <label>Address</label>
              <input type="text" placeholder="Enter current address" required />
              <input type="text" placeholder="Enter Residential address" required />
              <div className="column">
                <div className="select-boox">
                  <select>
                    <option hidden>State</option>
                    <option value="state">Abia</option>
                    <option value="state">Adamawa</option>
                    <option value="state">Anambra</option>
                    <option value="state">Bauchi</option>
                    <option value="state">Bayelsa</option>
                    <option value="state">Benue</option>
                    <option value="state">Borno</option>
                    <option value="state">Cross River</option>
                    <option value="state">Delta</option>
                    <option value="state">Ebonyi</option>
                    <option value="state">Edo</option>
                    <option value="state">Ekiti</option>
                    <option value="state">Enugu</option>
                    <option value="state">FCT-Abuja</option>
                    <option value="state">Gombe</option>
                    <option value="state">Imo</option>
                    <option value="state">Jigawa</option>
                    <option value="state">Kaduna</option>
                    <option value="state">Kano</option>
                    <option value="state">Kastina</option>
                    <option value="state">Kebbi</option>
                    <option value="state">Kogi</option>
                    <option value="state">Kwara</option>
                    <option value="state">Lagos</option>
                    <option value="state">Nasarawa</option>
                    <option value="state">Niger</option>
                    <option value="state">Ogun</option>
                    <option value="state">Ondo</option>
                    <option value="state">Osun</option>
                    <option value="state">Oyo</option>
                    <option value="state">Plateau</option>
                    <option value="state">Zamfara</option>
                    <option value="state">Rivers</option>
                    <option value="state">Sokoto</option>
                    <option value="state">Taraba</option>
                    <option value="state">Yobe</option>
                  </select>
                </div>
                <input type="text" placeholder="Enter your city" required />
              </div>
            </div>
          )}

          <button>Submit</button>
        </form>
      </section>
    </div>
  );
};

export default AdditionalInfo;
