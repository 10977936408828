import React from 'react';
import './reasons.css';

const Reasons = () => {


  return (
    <div className="reasons-page" id='contact'>
      <div className="left-secction">
        <h4>Why Choose Us?</h4>
        <h1>We Create Opportunity to Reach Potential.</h1>
        <ul className="reasons-list">
          <li>Reliable and Efficient Transport Solutions</li>
          <li>Comprehensive Moving and Storage Services.</li>
          <li>Flexible Courier and Logistics Options.</li>
          <li>Dedicated to Customer Satisfaction.</li>
          <li>Innovative Logistics Management.</li>
          <li>Commitment to Safety and Security.</li>
        </ul>
      </div>

      <div className="right-secction">
        <form className="request-form">
        <h2 className="form-header">Contact Us</h2>
        <div className="form-group">
            <label>Name:</label>
            <input type="text" placeholder=" Enter Name:"required/>
          </div>
          <div className="form-group">
            <label>Email Address:</label>
            <input type="email" placeholder=" Enter Email Address:" />
          </div>
          <div className="form-group">
            <label>Phone number:</label>
            <input type="tel" placeholder=" Enter Phone No:" required/>
          </div>
          <div className="form-group">
            <label>Subject:</label>
            <input type="text" placeholder="Enter Sub:"required/>
          </div>
          <div className="text-area">
            <label>Message:</label>
            <textarea placeholder='Send a Message' />
          </div>
          <button className="submit-button">Submit request</button>
        </form>
      </div>
    </div>
  );
};

export default Reasons;
