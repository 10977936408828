import React, { useState } from 'react';
import './seatselection.css';

const SeatSelection = ({ closeSeatSelection }) => {
    const [selectedSeats, setSelectedSeats] = useState([]);

    const seatLayout = [
        ['driver', null, '1', '2'],
        ['3', '4', '5', null],
        ['6', '7', null, '8'],
        ['9', '10', null, '11'],
        ['12', '13', '14', '15'],
    ];

    const toggleSeatSelection = (seatNumber) => {
        if (selectedSeats.includes(seatNumber)) {
            setSelectedSeats(selectedSeats.filter(seat => seat !== seatNumber));
        } else {
            setSelectedSeats([...selectedSeats, seatNumber]);
        }
    };

    return (
        <div className="seat-selection-container">
            <div className="seat-selection-header">
                <h2>Select Seat(s)</h2>
                <span className="close-btn" onClick={closeSeatSelection}>&times;</span>
            </div>
            <div className="seat-selection-guide">
                <div><span className="seat selected"></span>Selected Seat</div>
                <div><span className="seat available"></span>Available Seat</div>
                <div><span className="seat booked"></span>Booked Seat</div>
            </div>
            <div className="seat-map">
                {seatLayout.map((row, rowIndex) => (
                    <div key={rowIndex} className="seat-row">
                        {row.map((seat, seatIndex) => (
                            <div
                                key={seatIndex}
                                className={`seat ${seat ? (seat === 'driver' ? 'driver' : selectedSeats.includes(seat) ? 'selected' : 'available') : 'spacer'}`}
                                onClick={() => seat && seat !== 'driver' && toggleSeatSelection(seat)}
                            >
                                {seat === 'driver' ? (
                                    <img src="https://media.istockphoto.com/id/1206795553/vector/vector-drive-symbol-icon-design.jpg?s=612x612&w=0&k=20&c=2SFwRpNCaEWZWMIFG2kTTOQdY8XfHJHIc6laJtAHUa0=" alt="Driver" />
                                ) : seat}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <button className="continue-btn">Continue</button>
        </div>
    );
};

export default SeatSelection;
