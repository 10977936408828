import React, { useState } from 'react';
import './dashboard.css';
import Sidebar from './sidebar';
import Profile from './profile.js';
import Storage from './storage';
import ChangePassword from './changepassword.js';
import DashboardHeader from './dashboardheader';
import Trips from './trips.js';
import Logistics from './logistics';




function Dashboard() {
  const [selectedComponent, setSelectedComponent] = useState('Profile');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Trips':
        return <Trips />;
      case 'Storage':
        return <Storage />;
      case 'Logistics':
        return <Logistics />;
        case 'ChangePassword':
        return <ChangePassword></ChangePassword>;
      default:
        return <Profile />;
       
    }
  };

  return (
    <div>
      
    <div> <DashboardHeader></DashboardHeader></div>
    <div className="dashboard">
      <Sidebar setSelectedComponent={setSelectedComponent}></Sidebar>
      <div className="main-content">
        {renderComponent()}
      </div>
    </div>
    </div>
  );
}

export default Dashboard;
