import React, { useState } from 'react';
import './selection.css';
import DashboardHeader from '../dashboard/dashboardheader';
import Footer from '../footer/footer';
import SeatSelection from './seatselection'; 
import SiennaSeat from './siennaseat'; 

const Selection = () => {
  const [selectedBusType, setSelectedBusType] = useState(null);

  const handleViewSeatsClick = (busType) => {
    setSelectedBusType(busType);
  };

  const closeSeatSelection = () => {
    setSelectedBusType(null);
  };

  return (
    <div className="bus-type-container">
      <DashboardHeader />
      <h2>Trip Destination</h2>
      <h3>Select your bus type</h3>
      
      <div className="bus-card">
        <div className="bus-image">
          <img src={require('../../images/toyota.jpg')} alt="Ac Buses" />
        </div>
        <div className="bus-info">
          <h4>JET (Jet Mover)</h4>
          <p>Departure: <span>Rivers</span> · Arrival: <span>Lagos</span></p>
          <p><i className="icon-seats"></i> 4 seats (available) <i className="icon-time"></i> 06:30 AM</p>
          <p><i className="icon-adult"></i> Adult: 1</p>
        </div>
        <div className="price-info">
          <span className="price">₦10,000</span>
          <button 
            className="view-seats-btn" 
            onClick={() => handleViewSeatsClick('JET')}
          >
            View Seats
          </button>
        </div>
      </div>

      <div className="bus-card">
        <div className="bus-image">
          <img src={require('../../images/sienna.png')} alt="Sienna Bus" />
        </div>
        <div className="bus-info">
          <h4>Sienna</h4>
          <p>Departure: <span>Rivers</span> · Arrival: <span>Lagos</span></p>
          <p><i className="icon-seats"></i> 6 seats (available) <i className="icon-time"></i> 07:00 AM</p>
          <p><i className="icon-adult"></i> Adult: 1</p>
        </div>
        <div className="price-info">
          <span className="price">₦8,000</span>
          <button 
            className="view-seats-btn" 
            onClick={() => handleViewSeatsClick('Sienna')}
          >
            View Seats
          </button>
        </div>
      </div>

      {selectedBusType && (
        <div className="modal-overlay">
          <div className="modal-content">
            {selectedBusType === 'JET' && (
              <SeatSelection closeSeatSelection={closeSeatSelection} />
            )}
            {selectedBusType === 'Sienna' && (
              <SiennaSeat closeSeatSelection={closeSeatSelection} />
            )}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Selection;
