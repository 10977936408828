import React from 'react';
import { useNavigate } from 'react-router-dom';
import './services.css';


const Services = () => {
  const navigate = useNavigate();

  const handleReadMore = (path) => {
    navigate(path); 
  };

  return (
    
    <section className="services-section" id='service'>
      <div className="services-header">
        <h3>SERVICES</h3>
        <p className="description">
          Tramas Transport Company specializes in providing reliable and efficient road transportation and logistics services. Our comprehensive solutions ensure that your passengers and goods are delivered safely and on time. Trust us for your transportation needs.
        </p>
      </div>
      <div className="courier-services-container">
        <div className="service-card">
          <img 
            src="https://www.entrepreneurshipinabox.com/wp-content/uploads/transportation-business-ideas.jpg.webp" 
            alt="Transport Services" 
            className="service-image" 
          />
          <h3 className="service-title">Transport Services</h3>
          <p className="service-description">Reliable road transportation services for passengers and goods.</p>
          <button onClick={() => handleReadMore('/')} className="read-more-btn">READ MORE</button>
        </div>

        <div className="service-card">
          <img 
            src="https://img.freepik.com/free-photo/close-up-delivery-person-giving-parcel-client_23-2149095912.jpg?t=st=1724319143~exp=1724322743~hmac=4dd5b8b9ed284e6c94d02862e3ea5e1a9b77caaec10ae4e2da16941cdfcf51b8&w=900" 
            alt="Courier and Logistics" 
            className="service-image" 
          />
          <h3 className="service-title">Courier and Logistics</h3>
          <p className="service-description">Efficient solutions for courier services and logistics management.</p>
          <button onClick={() => handleReadMore('/logistics')} className="read-more-btn">READ MORE</button>
        </div>

        <div className="service-card">
          <img 
            src="https://img.freepik.com/free-photo/interior-large-distribution-warehouse-with-shelves-stacked-with-palettes-goods-ready-market_342744-1481.jpg?t=st=1724319120~exp=1724322720~hmac=2ca7e8a37107df97c8aa9c8ff095abe89f58c0cae489f40f6f48ba674321042d&w=900" 
            alt="Moving and Storage" 
            className="service-image" 
          />
          <h3 className="service-title">Moving and Storage</h3>
          <p className="service-description">Safe and secure moving and storage solutions for your needs.</p>
          <button onClick={() => handleReadMore('/storage')} className="read-more-btn">READ MORE</button>
        </div>
      </div>
    </section>
  );
};

export default Services;
