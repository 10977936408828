import React, { useState } from "react";
import "./trips.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";

const Trips = () => {
  const [bookings,bookingsChanged]=useState(['','','',''])
  const [openBooking,openBookingChanged]=useState(false)
  const [booking, bookingChanged]=useState({})
  return (
    <div className="booking-container">
      <div className="order-table">
        <div className="order-header">
          <h3>Booking History</h3>
          <button className="add-order">+ Add New Order</button>
        </div>
        <center>
        {
          openBooking?<div className="booked-items booked-items-grid">
            <BsArrowLeftCircleFill style={{fontSize:'30px'}} onClick={()=>openBookingChanged(false)}></BsArrowLeftCircleFill>
            <p></p>
            <p>Routes</p>
            <p style={{fontWeight:'normal'}}>Rivers (Port Harcourt)</p>
            <p></p>
            <p style={{fontWeight:'normal'}}>Lagos (Jibowu)</p>
            <p>Booking</p>
            <p style={{fontWeight:'normal'}}>Approved (Online)</p>
            <p>Contact Name</p>
            <p style={{fontWeight:'normal'}}>Ifeanyi Nwachukwu</p>
            <p>Contact Info</p>
            <p style={{fontWeight:'normal'}}>08185971473 (ifeanyibobby@gmail.com)</p>
            <p>Total Amount</p>
            <p style={{fontWeight:'normal'}}>30,500 NGN</p>
            <p>Booking Date</p>
            <p style={{fontWeight:'normal'}}>July 20,2024</p>
            <p>Departure Date</p>
            <p style={{fontWeight:'normal'}}>July 22nd, 2024</p>
            <p>Passenger</p>
            <p style={{fontWeight:'normal'}}>Adult</p>
            <p>Next of Kin</p>
            <p style={{fontWeight:'normal'}}>Gift Nwachukwu</p>
            <p>Routes</p>
            <p style={{fontWeight:'normal'}}>08064456532</p>



          </div>:<>{bookings.map(item =>  <div className="booked-items" onClick={()=>openBookingChanged(true)}>
            <div className="booked-items-spacebetween">
              <p>{"Rivers (Port Harcourt) ==> Lagos (Jibowu)"}</p>
              <p>
                <b>Approved (Online)</b>
              </p>
            </div>
            <div className="booked-items-spacebetween">
              <p>July 20th, 2024</p>
              <p>
                <b>30,500</b>
              </p>
            </div>
          </div>)}</>
        }
        </center>
        
       
        {/* <table>
        <thead>
          <tr>
          <th>S/N</th>
            <th>Name</th>
            <th>Location (From)</th>
            <th>Location (To)</th>
            <th>Number of Tickets</th>
            <th>Total Amount Paid</th>
            <th>Date of Trip</th>
            <th>Vehicle Type</th>
            <th>Status</th>
            <th>View Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>David Buckley</td>
            <td>Aba</td>
            <td>Aba</td>
            <td>1</td>
            <td>#10,000</td>
            <td>June 10, 2020</td>
            <td>AC Bus</td>
            <td><span className="status fulfilled">COMPLETED</span></td>
            <td><button className="view-details">View Details</button></td>
            <td>
              <button className="edit">✏️</button>
              <button className="delete">🗑️</button>
            </td>
          </tr>
        </tbody>
      </table> */}
      </div>
    </div>
  );
};

export default Trips;
