import React, { useState } from "react";
import "./logistics.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";

const Storage = () => {
  const [storageRecords, setStorageRecords] = useState([
    {
      location: "Lagos (Ikeja)",
      duration: "March 1st, 2024 - August 31st, 2024",
      status: "In Storage",
      contactName: "John Doe",
      contactInfo: "08012345678 (john.doe@example.com)",
      cost: "150,000 NGN",
      requestDate: "February 25th, 2024",
      items: "Household Items and Electronics",
    },
    {
      location: "Abuja (Wuse)",
      duration: "January 10th, 2024 - July 10th, 2024",
      status: "Collected by User",
      contactName: "Jane Smith",
      contactInfo: "08087654321 (jane.smith@example.com)",
      cost: "120,000 NGN",
      requestDate: "January 5th, 2024",
      items: "Office Furniture and Documents",
    },
    {
      location: "Port Harcourt (GRA)",
      duration: "May 1st, 2024 - November 1st, 2024",
      status: "In Storage",
      contactName: "Alice Johnson",
      contactInfo: "08023456789 (alice.johnson@example.com)",
      cost: "180,000 NGN",
      requestDate: "April 25th, 2024",
      items: "Appliances and Personal Belongings",
    },
    {
      location: "Kano (Nassarawa)",
      duration: "April 15th, 2024 - October 15th, 2024",
      status: "Collected by User",
      contactName: "Michael Brown",
      contactInfo: "08098765432 (michael.brown@example.com)",
      cost: "140,000 NGN",
      requestDate: "April 10th, 2024",
      items: "Tools and Machinery",
    },
  ]);

  const [openRecord, setOpenRecord] = useState(null);

  return (
    <div className="logistics-container">
      <div className="order-table">
        <div className="order-header">
          <h3>Storage Service History</h3>
          <button className="add-order">+ Add New Record</button>
        </div>
        <center>
          {
            openRecord !== null ? (
              <div className="delivered-items delivered-items-grid">
                <BsArrowLeftCircleFill 
                  style={{fontSize:'30px'}} 
                  onClick={() => setOpenRecord(null)} 
                />
                <p></p>
                <p>Storage Location</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].location}</p>
                <p>Storage Duration</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].duration}</p>
                <p>Status</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].status}</p>
                <p>Contact Name</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].contactName}</p>
                <p>Contact Info</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].contactInfo}</p>
                <p>Total Cost</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].cost}</p>
                <p>Request Date</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].requestDate}</p>
                <p>Items Stored</p>
                <p style={{fontWeight:'normal'}}>{storageRecords[openRecord].items}</p>
              </div>
            ) : (
              <>
                {storageRecords.map((record, index) => (
                  <div 
                    className="delivered-items" 
                    onClick={() => setOpenRecord(index)} 
                    key={index}
                  >
                    <div className="delivered-items-spacebetween">
                      <p>{record.location}</p>
                      <p><b>{record.status}</b></p>
                    </div>
                    <div className="delivered-items-spacebetween">
                      <p>{record.duration}</p>
                      <p><b>{record.cost}</b></p>
                    </div>
                  </div>
                ))}
              </>
            )
          }
        </center>
      </div>
    </div>
  );
};

export default Storage;
