import React, { useState, useEffect } from 'react';
import './signup.css';
import Login from '../login/login';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  
import { signup } from '../../service/users';

const Signup = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [showSignup, setShowSignup] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);  // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // State for confirm password visibility

  const navigate = useNavigate();

  const handleLoginClick = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  const closePopup = () => {
    setShowLogin(false);
    setShowSignup(false);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }

    // Validation
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.gender || !formData.password || !formData.confirmPassword) {
      setError('All fields are required');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setError('');

    try {
      const result = await signup({
        firstname: formData.firstName,
        lastname: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        gender: formData.gender,
        password: formData.password,
      });

      if (result!==200) {
        setError('Error creating profile');
      } else {
        setShowSuccessPopup(true);

        setTimeout(() => {
          navigate('/validate-account');
        }, 3000);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  // Effect to clear error message after 3 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 3000);

      // Cleanup the timer on component unmount or when error changes
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      {showSignup && (
        <div className="overlay-container">
          <div className="blur-bg-overlay"></div>
          <div className="signup-container">
            <div className="welcomee-section">
              <h2>Create Account</h2>
              <p>To become a part of our community, please sign up using your personal information.</p>
            </div>
            <div className="form-content">
              <h2>SIGNUP</h2>
              <button className="close-btn" onClick={onClose}>Close</button>
              <form onSubmit={handleSubmitClick}>
                {error && <div className="error-message">{error}</div>}
                <div className="input-field">
                  <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleInputChange}  />
                </div>
                <label htmlFor="gender">Gender</label>
                <div className="gender-options">
                  <input type="radio" name="gender" value="Male" id="male" onChange={handleInputChange}  />
                  <label htmlFor="male">Male</label>
                  <input type="radio" name="gender" value="Female" id="female" onChange={handleInputChange} />
                  <label htmlFor="female">Female</label>
                </div>
                <div className="input-field password-field">
  <input
    type={showPassword ? 'text' : 'password'}
    name="password"
    placeholder="Password"
    value={formData.password}
    onChange={handleInputChange}
  />
  <span onClick={togglePasswordVisibility} className="password-toggle-icon">
    {showPassword ? <FaEyeSlash /> : <FaEye />}
  </span>
</div>
<div className="input-field password-field">
  <input
    type={showConfirmPassword ? 'text' : 'password'}
    name="confirmPassword"
    placeholder="Confirm password"
    value={formData.confirmPassword}
    onChange={handleInputChange}
  />
  <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
  </span>
</div>
                <div className="policy-text">
                  <input type="checkbox" id="policy" />
                  <label htmlFor="policy">
                    I agree to the
                    <a href="TandC" className="option">Terms & Conditions</a>
                  </label>
                </div>
                <button type="submit">Sign Up</button>
              </form>
              <div className="bottom-link">
                Already have an account?
                <button onClick={handleLoginClick} id="login-link">Login</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showLogin && <Login onClose={closePopup} />} 

      {showSuccessPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <h3>Verification Code Sent</h3>
            <p>A verification code has been sent to your email. Please check your inbox.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
