// apiService.js

import axios from "axios";

const BASE_URL = "http://localhost:5001/users";

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const login = async (email, password) => {
  try {
    var response = await axios.post(`${BASE_URL}/login`, {
      email: email,
      password: password,
    });
    console.log(response);
    if (response.status === 200) {
      localStorage.setItem("email", email);
      localStorage.setItem("token", response.data);
      localStorage.setItem("isLoggedIn", true);
    }
    return response.status;
  } catch (error) {
    return 0;
  }
};
export const logout = async () => {
  localStorage.setItem("token", "");
  localStorage.setItem("isLoggedIn", false);
};

export const signup = async (userData) => {
  try {
    var response = await axios.post(`${BASE_URL}/signup`, userData);
    console.log(response.data);
    if (response.status === 200) {
      return 200;
    } else {
      return 0;
    }
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const fetchProfile = async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-profile?email=${email}`, {
      headers: getAuthHeaders(),
    });
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      logout();
      return "Session has expired, sign in again";
    }
  } catch {
    return "Invalid";
  }
};

// Update user profile
export const updateProfile = async (profileData) => {
  const response = await axios.post(`${BASE_URL}/update-profile`, profileData, {
    headers: getAuthHeaders(),
  });
  return response.status;
};

// Change password
export const changePassword = async (passwordData) => {
  try {
    console.log(passwordData)
    const response = await axios.post(
      `${BASE_URL}/change-password`,
      passwordData,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

// Forgot password
export const forgotPassword = async (email) => {
  const response = await fetch(`${BASE_URL}/forgot-password?email=${email}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  const data = await response.json();
  return data;
};
