import React from 'react';
import './contact.css';

const Contact = () => {
  return (
    <div className="contact-info-container" id='contact'>
      <div className="info-box">
        <div className="icon contact-icon"></div>
        <h3>CONTACT US</h3>
        <p>Captainbulusss@yahoo.com<br />mrbenejike@gmail.com</p>
      </div>
      <div className="info-box">
        <div className="icon call-icon"></div>
        <h3>CALL CENTRE</h3>
        <p>+234 7053 880 045 <br /> +234 7037 103 488 </p>
      </div>
      <div className="info-box">
        <div className="icon hours-icon"></div>
        <h3>WORKING HOURS</h3>
        <p>Mon-Sun 5AM-9PM</p>
      </div>
    </div>
  );
};

export default Contact;
