import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer" id='footer'>
      <div className="footer-content">
        <div className="footer-about">
        <h3> Contact Us</h3>
          <p>EMAIL1 : Captainbulusss@yahoo.com</p>
          <p>EMAIL2 : mrbenejike@gmail.com</p>
         <p>PHONE NUM1 📞 : +234 7053 880 045 </p> 
          <p>PHONE NUM2 📞 : +234 7037 103 488 </p>
          
        </div>
        <div className="footer-services">
          <h3>Our Services</h3>
          <ul>
            <li><a className='linnks' href='/'>Transport Services</a></li>
            <li>< a href='/logistics'>Courier and Logistics</a></li>
            <li>< a href='/storage'> Moving and Storage</a></li>
          </ul>
        </div>
        <div className="footer-links">
          <h3>Useful Links</h3>
          <ul>
            <li>Help Link</li>
            <li>Terms & Conditions</li>
            <li>FAQ</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Tramas Transport © 2024. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
