import {Route, Routes} from 'react-router'
import './App.css';
import Homepage from './components/homepage/homepage';
import Dashboard from './components/dashboard/dashboard';
import Signup from './components/login/signup';
import Login from './components/login/login';
import Selection from './components/booking/selection';
import About from './components/about/about';
import Logistics from './components/services/logistics';
import Storage from './components/services/storage';
import AdditionalInfo from './components/booking/additionalinfo';



function App() {
  return (
  <Routes>
    <Route path='/' element={<Homepage></Homepage>}></Route>
    <Route path='/dashboard' element={<Dashboard></Dashboard>}></Route>
    <Route path='/signup' element={<Signup></Signup>}></Route>
    <Route path='/login' element={<Login></Login>}></Route>
    <Route path='/about' element={<About></About>}></Route>
    <Route path='/selection' element={<Selection></Selection>}></Route>
    <Route path='/logistics' element={<Logistics></Logistics>}></Route>
    <Route path='/storage' element={<Storage></Storage>}></Route>
    <Route path='/additionalinfo' element={<AdditionalInfo></AdditionalInfo>}></Route>
    

    
  </Routes>
  );
}

export default App;

