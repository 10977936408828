import React from 'react';
import './sidebar.css';

const Sidebar = ({ setSelectedComponent }) => {
  return (
    <div className="sidebar">
      <h2>TRAMAS</h2>
      <nav>
        <ul>
          
        <li><a href="#" onClick={() => setSelectedComponent('Profile')}>Profile</a></li>
          <li><a href="#" onClick={() => setSelectedComponent('Trips')}>Trips</a></li>
          <li><a href="#" onClick={() => setSelectedComponent('Storage')}>Storage</a></li>
          <li><a href="#" onClick={() => setSelectedComponent('Logistics')}>Logistics</a></li>
          <li><a href="#" onClick={() => setSelectedComponent('ChangePassword')}>Change Password</a></li>
          <li><a href="/" onClick={() => setSelectedComponent('logout')}>Logout</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
