import React from 'react';
import './about.css';
import { FaBullseye, FaLightbulb, FaHandsHelping } from 'react-icons/fa';
import DashboardHeader from '../dashboard/dashboardheader';
import LocationCards from './locationCards';
import Footer from '../footer/footer';


const About = () => {
     
  return (
    <div>
        <div><DashboardHeader></DashboardHeader></div>
      <div className="about-container">
        <div className="about-content">
          <h1>Welcome to Anambra Movement Transport Company LTD</h1>
          <p>
            Your one-stop platform for booking bus tickets online. We are a team of passionate individuals dedicated to making bus travel easy, convenient, and affordable for everyone.
            The company which was founded in 2024 has become an icon in the transportation industry, providing safe, enjoyable, and affordable travel to millions of passengers annually.
          </p>
          <p>
            While Anambra Movement Transport Co. is well known for its regularly scheduled passenger service, the company also provides a number of other services for its customers such as value-priced same-day and early-next-day haulage and package delivery to numerous destinations in Nigeria.
          </p>
          <div className="about-buttons">
            <a href="#location-cards" className="term-btn">OUR TERMINALS</a>
          </div>
        </div>
      </div>

      <div className="mv-container">
        <div className="mv-card">
          <FaBullseye className="mv-icon" />
          <h3>Mission Statement</h3>
          <p>To provide efficient transport services that add value to our customers and to grow our business through safe operations, innovation, and continued excellence.</p>
        </div>
        <div className="mv-card">
          <FaLightbulb className="mv-icon" />
          <h3>Our Vision</h3>
          <p>To be the transport company of choice and leader in providing quality and customized service.</p>
        </div>
       
      </div>
      <div className="core-values-container">
      <div className="header">
      <FaHandsHelping className="mv-icon" />
      <h2>Our Core Values</h2>
        <p>We listen and provide service excellence to our customers.</p>
        <p>We continually strive to be an employer of choice by:</p>
      </div>
      <div className="values">
        <div className="value">
          <div className="underline"></div>
          <p>Investing in our staff, supporting their growth and continued development</p>
        </div>
        <div className="value">
          <div className="underline"></div>
          <p>Functioning always as a team and fostering a positive work environment</p>
        </div>
        <div className="value">
          <div className="underline"></div>
          <p>Being an organization that strives for the highest ethical standards, values, safety, honesty and mutual trusts</p>
        </div>
      </div>
    </div>
    <div id="location-cards">
        <LocationCards />
      </div>
      <Footer></Footer>
    </div>
  );
};

export default About;
