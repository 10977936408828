import React from 'react';
import './steps.css';

const Steps = () => {
    return (
        <div className="how-it-works-container" id='steps'>
          <h3>HOW IT WORKS</h3>
          <div className="steps">
            <div className="step-item">
              <div className="icon-container">
               
              </div>
              <div className="step-number">01</div>
              <h4>Seat Reservation</h4>
              <p>Select your preferred seat and reserve it online.</p>
            </div>
    
            <div className="step-item">
              <div className="icon-container">
     
              </div>
              <div className="step-number">02</div>
              <h4>Payment</h4>
              <p>Secure your booking by making an online payment.</p>
            </div>
    
            <div className="step-item">
              <div className="icon-container">
               
              </div>
              <div className="step-number">03</div>
              <h4>Ticket Confirmation</h4>
              <p>Receive your e-ticket via email or SMS.</p>
            </div>
    
            <div className="step-item">
              <div className="icon-container">

              </div>
              <div className="step-number">04</div>
              <h4>Boarding</h4>
              <p>Present your e-ticket at the terminal and board the bus.</p> 
            </div>
          </div>
        </div>
      );
    };
    

export default Steps;
